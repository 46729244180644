import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentComponent } from '@booking/app/components/document/document.component';

import { PaymentStubComponent } from '@booking/app/components/payment-stub/payment-stub.component';
import { StatusStubComponent } from '@booking/app/components/status-stub/status-stub.component';
import { BookComponent } from '@booking/app/pages/book/book.component';
import { BookModule } from '@booking/app/pages/book/book.module';

import { MainLayoutComponent } from './main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'new',
        component: BookComponent,
      },
      {
        path: 'payment',
        component: PaymentStubComponent,
      },
      {
        path: 'status',
        component: StatusStubComponent,
      },
      {
        path: 'documents/:uuid',
        component: DocumentComponent,
      }
    ],
  },
];

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [CommonModule, RouterModule.forChild(routes), BookModule],
})
export class MainLayoutModule {}
