import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.sass'],
})
export class TourComponent implements OnInit {
  @ViewChild('inputTourFile') private inputTourFile: ElementRef<HTMLInputElement>;
  @Output() public onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onPrevious: EventEmitter<void> = new EventEmitter<void>();

  public cities: any = [
    'г. Москва, Московская область',
    'г. Санкт-Петербург, Санкт-Петербург',
    'г. Воронеж, Воронежская область',
    'г. Краснодар, Краснодарский край',
    'г. Новосибирск, Новосибирская область',
  ];

  public fileTourLoadError: boolean;
  public tourData: FormGroup;
  public selectedRoomType = 'single';
  public searchLoader: boolean;
  public today = new Date();
  constructor() {}

  ngOnInit(): void {
    this.setForm();
    this.isSmallScreen = window.innerWidth <= 640;
  }

  public isSmallScreen: boolean;

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 640;
  }

  private setForm(): void {
    this.tourData = new FormGroup({
      city: new FormControl('', [Validators.required]),
      price: new FormControl('', []),
      living_start: new FormControl('', [Validators.required]),
      living_end: new FormControl('', [Validators.required]),
      room_type: new FormControl('single', []),
    });
  }

  public resetFileByInstance(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.tourData.controls['file'].reset();
    this.inputTourFile.nativeElement.value = '';
  }

  public pickIconForComplexField(): string {
    const start = this.pickIcon('living_start');
    const end = this.pickIcon('living_end');

    if (start === end) return start;
    if (start === 'error' || end === 'error') return 'error';

    return 'valid_mark';
  }

  public getStyleColorForComplexField(): string {
    const start = this.pickColor('living_start');
    const end = this.pickColor('living_end');

    if (start === end) return `color: ${start}`;

    if (start === ERROR_COLOR || end === ERROR_COLOR) return `color: ${ERROR_COLOR}`;

    return `color: ${UNTOUCHED_COLOR}`;
  }

  public pickIcon(field: string): string {
    if (!this.tourData.controls[field].touched) return 'valid_mark';
    if (this.tourData.controls[field].errors !== null) return 'error';

    return 'valid_mark';
  }

  public getStyleColor(field: string): string {
    return `color: ${this.pickColor(field)};`;
  }

  private pickColor(field: string): string {
    if (!this.tourData.controls[field].touched) return UNTOUCHED_COLOR;
    if (this.tourData.controls[field].errors !== null) return ERROR_COLOR;

    return VALID_COLOR;
  }

  public attachTourFile(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.fileTourLoadError = false;
    this.inputTourFile.nativeElement.click();
  }

  public handleTourFile(e: Event): void {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (!e.target.files) return;
    if (!e.target.files[0]) return;

    if (e.target.files[0].size > 5 * 1024 * 1024) {
      this.fileTourLoadError = true;
      return;
    }

    this.tourData.controls['file'].setValue(e.target.files[0]);
  }

  public onClick(): void {
    this.tourData.markAllAsTouched();
    if (this.tourData.status === 'INVALID') return;
    this.onNext.emit(this.tourData.value);
  }

  public handleInputClick(el: MatDatepicker<any>): void {
    el.open();
  }

  public changeSelect(change: any) {
    this.tourData.controls['room_type'].setValue(change.value);
  }
}

const ERROR_COLOR = 'red';
const VALID_COLOR = '#1A85E8';
const UNTOUCHED_COLOR = '#B9BCD6';
