import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { BooksComponent } from './books/books.component';
import { DocsComponent } from './docs/docs.component';

@NgModule({
  declarations: [BooksComponent, DocsComponent],
  imports: [CommonModule, MatButtonModule],
})
export class AdminModule {}
