import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'lodash';

import { getStatusLabel } from '@booking/app/scripts/booking';
import { downloadCSV } from '@booking/app/scripts/export_csv';
import { BooksService } from '@booking/app/services/books.service';

@Component({
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.sass'],
})
export class DocsComponent implements OnInit {
  public isSmallScreen: boolean;
  public titles: string[] = [
    'ID заявки',
    'Время и дата',
    'ФИО',
    'Телефон',
    'Город проживания',
    'Статус',
  ];
  public bookEntity:
    | {
        id: number;
        status: string;
        phone: string;
        city: string;
        name: string;
        book_id: number;
        created_at: Date;
      }[]
    | undefined;

  constructor(private booksService: BooksService, private router: Router) {}

  @HostListener('window:resize', ['$event']) onResize() {
    this.isSmallScreen = window.innerWidth <= 640;
  }

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 640;
    this.getDocs();
  }

  public genLabel: { (key: string): string } = getStatusLabel;

  public getDocs() {
    this.booksService.getDocumentsRequests().subscribe((b) => {
      this.bookEntity = map(b, (el) => {
        return { ...el.request, book_id: el.document.booking_id };
      });
    });
  }

  public download(): void {
    this.booksService.downloadDocDb().subscribe((v) => {
      downloadCSV(v, 'База документов');
    });
  }

  public refresh(): void {
    this.getDocs();
  }

  public navigateToEntity(id: number): void {
    this.router.navigate(['admin', 'document', id]);
  }
}
