import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.sass'],
})
export class StepperHeaderComponent implements OnInit, OnChanges {
  @Input() stepId: number = 0;

  public steps: any = [{ icon: 'check_list' }, { icon: 'personal_data' }, { icon: 'factory' }];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  public getBgStyle(condition: boolean): string {
    return `background-color: ${this.getColor(condition)};`;
  }

  private getColor(cond: boolean, err?: boolean): string {
    if (err) return '#f44336';

    return cond ? '#1a85e8' : '#B9BCD6';
  }

  public getColorStyle(condition: boolean, err?: boolean): string {
    return `color: ${this.getColor(condition, err)};`;
  }
}
