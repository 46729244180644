<div class="wrapper">
  <h1>{{ detail?.name || 'Загрузка...' }}</h1>
</div>
<div class="bg-white">
  <div class="wrapper">
    <div class="group">
      <div class="manage">
        <h2>Запрашиваемые документы</h2>
        <ul>
          <li *ngFor="let item of resources">{{ item }}</li>
        </ul>

        <div class="callout" *ngIf="detail?.document?.comment">
          <h4>Комментарий</h4>
          <p>{{ detail?.document?.comment }}</p>
        </div>
        <h2>Статус задачи</h2>
        <mat-radio-group [(ngModel)]="selectedBox" (ngModelChange)="setStatus()">
          <mat-radio-button
            *ngFor="let item of boxes; let i = index"
            [style]="getBg(i)"
            [value]="item.value"
            >{{ item.label }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="cards">
        <div class="card-info">
          <p class="head">Город проживания</p>
          <p>{{ detail?.city }}</p>
          <p class="head">Даты командировки</p>
          <p>{{ getRange(detail?.living_start, detail?.living_end) }}</p>
          <p class="head">Тип номера</p>
          <p>{{ getLabel(detail?.room_type) }}</p>
          <p class="head">Желаемая стоимость</p>
          <p>{{ detail?.price }} ₽/сут.</p>
        </div>
        <div class="card-info">
          <p class="head">Телефон</p>
          <p>{{ detail?.phone }}</p>
          <p class="head">Электронная почта</p>
          <p>{{ detail?.email }}</p>
          <p class="head">Как связаться</p>
          <p>{{ getCommunicate(detail) }}</p>
          <p class="head">Комментарий</p>
          <p>{{ detail?.comment }}</p>
          <span
            (click)="download()"
            class="file"
            >{{ detail?.attachment?.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
