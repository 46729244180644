<div class="checking">
  <h2>Проверка данных</h2>
  <div class="group-data">
    <h4>Фамилия имя и Отчество</h4>
    <span>{{ data.name || '' }}</span>
    <h4>Телефон</h4>
    <span>{{ data.phone || '' }}</span>
    <h4>Электронная почта</h4>
    <span>{{ data.email || '' }}</span>
    <h4>Как связаться</h4>
    <span>{{ getCommunication(data) }}</span>
    <h4>Комментарий</h4>
    <span>{{ data.name || '' }}</span>
  </div>

  <div class="group-data">
    <h4>Город проживания</h4>
    <span>{{ data.city || '' }}</span>
    <h4>Даты командировки</h4>
    <span>{{ getRange(data.living_start, data.living_end) }}</span>
    <h4>Тип номера</h4>
    <span>{{ gteRoomType(data.room_type) }}</span>
    <h4>Желаемая стоимость</h4>
    <span>{{ data.price || 0 }} ₽/сут.</span>
  </div>
  <div class="button-controls">
    <button
      mat-raised-button
      class="back-button"
      (click)="previous()"
    >
      Вернуться назад
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="accent"
    >
      Оставить заявку
    </button>
  </div>
</div>
