import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  constructor(private http: HttpClient) {}

  public sendBook(form: any): Observable<any> {
    return this.http.post('book', form);
  }

  public getBooks(status: string): Observable<any> {
    return this.http.get(`books/requests?status=${status}`);
  }

  public getBook(bookId: string): Observable<any> {
    return this.http.get(`books/${bookId}`);
  }

  public sendToClient(bookId: number, form: any): Observable<any> {
    return this.http.post(`books/${bookId}/payment`, form);
  }

  public getPaymentLink(session: string): Observable<any> {
    return this.http.get(`payment/link?session=${session}`);
  }

  public getPaymentStatus(session: string): Observable<any> {
    return this.http.get(`payment/status?session=${session}`);
  }

  public sendFormForDocuemnts(bookId: string, form: any): Observable<any> {
    return this.http.post(`books/${bookId}/documents`, form);
  }

  public getDocumentsRequests(): Observable<any> {
    return this.http.get('documents/requests');
  }

  public setDocuemntStatus(bookID: number, status: number): Observable<any> {
    return this.http.put(`documents/${bookID}/status`, { value: status });
  }

  public updateStatus(bookId: string, form: any): Observable<any> {
    return this.http.put(`payment/link?session=${bookId}`, form);
  }

  public getStatic(uuid: string): Observable<any> {
    return this.http.get(`static/${uuid}`, { responseType: 'blob' });
  }

  public getShortBook(uuid: string): Observable<any> {
    return this.http.get(`books/short/${uuid}`);
  }

  public downloadBookDb(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get('books/back_up', { headers, responseType: 'text' });
  }

  public downloadDocDb(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get('documents/back_up', { headers, responseType: 'text' });
  }
}
