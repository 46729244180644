import { Component } from '@angular/core';
import { AdminService } from '@uslugi/app/services/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'booking';

  constructor(private admin: AdminService) {
    this.admin;
  }
}
