import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { BookDetail } from '@booking/app/schema/book';
import {
  concatDates,
  defineRoomType,
  getCommunicate,
  getRequestDocs,
} from '@booking/app/scripts/booking';
import { downloadBlob } from '@booking/app/scripts/export_csv';
import { BooksService } from '@booking/app/services/books.service';

@Component({
  selector: 'app-document-info',
  templateUrl: './document-info.component.html',
  styleUrls: ['./document-info.component.sass'],
})
export class DocumentInfoComponent implements OnInit {
  public detail: BookDetail | undefined;
  public diff: number;
  public resources: string[] | undefined;
  public selectedBox: number = 0;
  public boxes: any = [
    {
      label: 'Документы в работе',
      checked: false,
      value: 0,
    },
    {
      label: 'Отправлены по Email',
      checked: false,
      value: 1,
    },
    {
      label: 'Отправлены почтой',
      checked: false,
      value: 2,
    },
    {
      label: 'Получены клиентом',
      checked: false,
      value: 3,
    },
  ];

  constructor(private bookService: BooksService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getRequest();
    this.initBook();
  }

  public getLabel: { (t: string | undefined): string } = defineRoomType;
  public getRange: { (start: string | undefined, end: string | undefined): string } = concatDates;
  public getCommunicate: { (t: any): string } = getCommunicate;

  private initBook(): void {
    const id = this.getCurrentId();
    if (!id) return;

    this.getInfo(id);
  }

  private getRequest(): void {
    this.bookService.getDocumentsRequests().subscribe((v) => {
      const currentRequest = v.filter((docReq: any) => docReq.document.booking_id === this.detail?.id )[0]
      this.selectedBox = getIndexStatusDoc(currentRequest.request.status)
    })
  }

  private getCurrentId(): string | null {
    return this.route.snapshot.paramMap.get('id');
  }

  public getInfo(id: string): void {
    this.bookService.getBook(id).subscribe((v: BookDetail) => {
      this.detail = v;

      this.resources = getRequestDocs(this.detail.document);
      this.diff = moment(v.living_end).diff(moment(v.living_start), 'days');
    });
  }

  public genLinkForPayture(): string {
    if (!this.detail?.payment) return '';

    return this.detail.payment.link;
  }

  public download(): void {
    if (!this.detail?.attachment) return;

    this.bookService.getStatic(this.detail.attachment.uuid).subscribe((blob) => {
      if (!this.detail?.attachment) return;

      downloadBlob(blob, this.detail.attachment.name);
    });
  }

  public getBg(i: number): string {
    if (this.selectedBox === i) {
      return 'background-color: #F7FAFE;';
    }

    return '';
  }

  public setStatus(): void {
    if (!this.detail?.id) return;

    this.bookService.setDocuemntStatus(this.detail.id, this.selectedBox).subscribe();
  }
}


const getIndexStatusDoc = (key: string): number => {
  if (key === 'active') return 0
  if (key === 'sent_via_email') return 1
  if (key === 'sent_via_mail') return 2
  if (key === 'done') return 3

  return 0
};
