import * as moment from 'moment';

export const defineRoomType = (roomType: string | undefined): string => {
  if (!roomType) return '';
  if (roomType == 'single') return 'одноместный';
  if (roomType == 'double') return 'двухместный';
  if (roomType == 'triple') return 'трехместный';

  return '';
};

export const concatDates = (start: string | undefined, end: string | undefined): string => {
  if (!start || !end) return '';

  return `${moment(start).format('DD.MM.YYYY')} - ${moment(end).format('DD.MM.YYYY')}`;
};

export const getCommunicate = (book: any): string => {
  if (!book) return '';

  const ways = [];

  if (book.is_email) ways.push('по электронной почте');
  if (book.is_messenger) ways.push('через мессенджер');
  if (book.is_phone) ways.push('по телефону');
  if (book.is_sms) ways.push('СМС');

  return ways.join(', ').toString();
};
export const getRequestDocs = (doc: any): string[] | undefined => {
  if (!doc) return;

  const ways = [];

  if (doc.is_resipient) ways.push('Чек об оплате');
  if (doc.is_certificate) ways.push('Справка о проживании');
  if (doc.is_price) ways.push('Прайс гостиницы');
  if (doc.is_license) ways.push('Лицензия');

  return ways;
};

export const getStatusLabel = (key: string): string => {
  if (key === 'active') return '🔥 В работе';
  if (key === 'sent_via_email') return '📧 отправлено по эл почте';
  if (key === 'sent_via_mail') return '📦 отправлено по почте';
  if (key === 'done') return 'выполнено';

  return '';
};
