import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from '@uslugi/app/services/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.checkAdmin().subscribe({
      next: (res) => {
        if (!res) return this.sessionService.auth.next(false);
        this.sessionService.auth.next(true);
      },
    });
  }

  public checkAdmin(): Observable<Admin | undefined> {
    return new Observable((subscriber) => {
      this.getMe().subscribe({
        next: (res) => subscriber.next(res || new Admin()),
        error: (err) => subscriber.next(undefined),
      });
    });
  }

  public getMe(): Observable<Admin> {
    return this.http.get<Admin>(`getMe`);
  }
}

export class Admin {
  id: number;
}
