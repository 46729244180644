import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'lodash';

import { downloadCSV } from '@booking/app/scripts/export_csv';
import { BooksService } from '@booking/app/services/books.service';

@Component({
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.sass'],
})
export class BooksComponent implements OnInit {
  public titles: string[] = [
    'ID заявки',
    'Время и дата',
    'ФИО',
    'Телефон',
    'Город проживания',
    'Статус',
  ];
  public activeIndex: number = 0;
  public isSmallScreen: boolean;
  public bookEntity:
    | {
        id: number;
        status: string;
        phone: string;
        city: string;
        name: string;
        book_id: number;
        created_at: Date;
      }[]
    | undefined;

  constructor(private booksService: BooksService, private router: Router) {}

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 640;
  }

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 640;
    this.getBooksByQyery('active', 0);
  }

  public genLabel(key: string): string {
    if (key === 'active') return '🔥 Активный запрос';
    if (key === 'pending') return '💳 Ожидает оплату';
    if (key === 'cancel') return '🤯 Отмена';
    if (key === 'error') return '🤖 Ошибка';
    if (key === 'success') return '✅ Успех';

    return '';
  }

  public getBooksByQyery(query: string, id: number) {
    this.activeIndex = id;
    this.booksService.getBooks(query).subscribe((b) => {
      this.bookEntity = map(b, (el) => {
        return { ...el.request, book_id: el.payment.booking_id };
      });
    });
  }

  public download(): void {
    this.booksService.downloadBookDb().subscribe((v) => {
      downloadCSV(v, 'База бронирований');
    });
  }

  public refresh(): void {
    this.getBooksByQyery('active', this.activeIndex);
  }

  public navigateToEntity(id: number): void {
    this.router.navigate(['admin', 'book', id]);
  }

  public getStyleProps(index: number): string | void {
    if (index === this.activeIndex) return 'color: #2A2B33;';
  }
}
