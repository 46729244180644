<div class="wrapper">
  <div class="button-controls">
    <h1>Запросы на документы</h1>
  </div>
</div>
<div class="bg-white">
  <div class="wrapper">
    <div class="header">
      <h2>Активные заявки</h2>
      <div class="button-controls">
        <button
          mat-button
          class="pale"
          (click)="refresh()"
        >
          Обновить
        </button>
        <button
          mat-raised-button
          [disabled]="!bookEntity || !bookEntity.length"
          color="accent"
          (click)="download()"
        >
          Скачать базу
        </button>
      </div>
    </div>
    <div
      class="table"
      *ngIf="bookEntity?.length && !isSmallScreen"
    >
      <div class="header">
        <div class="row">
          <div
            class="cell"
            *ngFor="let title of titles"
          >
            {{ title }}
          </div>
        </div>
      </div>
      <div class="body">
        <div
          class="row"
          (click)="navigateToEntity(entity.book_id)"
          *ngFor="let entity of bookEntity"
        >
          <div class="cell">{{ entity.id }}</div>
          <div class="cell">{{ entity.created_at | date: 'dd.MM.YYYY hh:mm' }}</div>
          <div class="cell">{{ entity.name }}</div>
          <div class="cell">{{ entity.phone }}</div>
          <div class="cell">{{ entity.city }}</div>
          <div class="cell">{{ genLabel(entity.status) }}</div>
        </div>
      </div>
    </div>
    <div class="mobile-table" *ngIf="isSmallScreen">
      <div
        class="row"
        (click)="navigateToEntity(entity.book_id)"
        *ngFor="let entity of bookEntity"
      >
        <div class="status-with-id">
          <div
            class="mobile-cell"
            style="font-weight: 600"
          >
            {{ entity.name }}
          </div>
          <div class="mobile-cell">ID: {{ entity.id }}</div>
        </div>
        <div class="mobile-cell">{{ genLabel(entity.status) }}</div>
        <div class="mobile-cell">{{ entity.created_at | date: 'dd.MM.YYYY hh:mm' }}</div>
        <div class="mobile-cell">{{ entity.phone }}</div>
        <div class="mobile-cell">{{ entity.city }}</div>
      </div>
    </div>
    <h3 *ngIf="!bookEntity?.length">Записей нет</h3>
  </div>
</div>
