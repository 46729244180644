<div class="wrapper">
  <div class="box" *ngIf="!success">
    <div
      class="message"
      *ngIf="isDocs"
    >
      <mat-card>
        <img
          src="../../../assets/images/success.svg"
          alt="success"
        />
        <h2>Оплата прошла успешно</h2>
        <p>
          Пройдите по ссылке, чтобы запросить необходимые документы для отчетности. Менеджер
          свяжется с вами, когда документы будут готовы.
        </p>
        <p>Письмо с ссылкой продублировано вам на почту.</p>
        <button
          mat-raised-button
          color="accent"
          (click)="reqBooks()"
        >
          Запросить документы об оплате
        </button>
      </mat-card>
    </div>
    <div
      class="form"
      *ngIf="!isDocs"
    >
      <h1>Заявка на отчётные документы</h1>
      <mat-card>
        <h2>Выберите документы</h2>
        <ul>
          <li *ngFor="let box of boxes; let i = index">
            <mat-checkbox
              [checked]="box.checked"
              (click)="setChecked($event, i)"
            >
              {{ box.label }}
            </mat-checkbox>
          </li>
        </ul>
        <form [formGroup]="form">
          <mat-form-field
            appearance="outline"
            floatLabel="never"
          >
            <mat-label>Комментарий</mat-label>
            <textarea
              matInput
              formControlName="comment"
            ></textarea>
          </mat-form-field>
        </form>
        <button
          mat-raised-button
          color="accent"
          (click)="submit()"
        >
          Запросить документы
        </button>
      </mat-card>
      <mat-card>
        <h2>Данные о поездке</h2>
        <div class="box-info">
          <h4>Фамилия Имя и Отчество</h4>
          <p>{{ detail.book.name }}</p>
          <h4>Телефон</h4>
          <p>{{ detail.book.phone }}</p>
          <h4>Электронная почта</h4>
          <p>{{ detail.book.email }}</p>
        </div>
        <div class="box-info">
          <div class="hotel-preview">
            <div class="icon">
              <mat-icon
                svgIcon="factory"
                color="primary"
              ></mat-icon>
              <h2>{{ detail.hotel.name }}</h2>
            </div>

            <p>{{ detail.hotel.address }}</p>
            <a [href]="detail.hotel.placement">
              <mat-icon svgIcon="baloon"></mat-icon>
              Смотреть на карте</a
            >
          </div>
          <h4>Тип номера</h4>
          <p>{{ defineRoomType(detail.hotel.room_type) }}</p>
          <h4>Даты проживания</h4>
          <p>{{ defineRange(detail.hotel.living_start, detail.hotel.living_end) }}</p>
        </div>
      </mat-card>
    </div>
  </div>

  <div
    class="status"
    *ngIf="success"
  >
    <mat-card>
      <h2>Запрос на отчётные документы взят в работу</h2>
      <p>Свяжемся с вами, когда документы будут готовы</p>
      <img
        src="../../../assets/images/info.png"
        alt="success"
      />
    </mat-card>
  </div>
</div>
