import { Injectable } from '@angular/core';
import { SessionService } from '@uslugi/app/services/session.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@booking/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private session: SessionService) {}

  public canActivate(): Promise<boolean> {
    return this.checkAuth();
  }

  public canActivateChild(): Promise<boolean> {
    return this.checkAuth();
  }

  private checkAuth(): Promise<boolean> {
    const subscriber: Subject<any> = new Subject();
    return new Promise<boolean>((resolve, reject) => {
      this.session.auth.pipe(takeUntil(subscriber)).subscribe((auth) => {
        if (auth !== undefined) {
          resolve(auth);
          if (!auth) {
            window.location.href = `${environment.uslugiURL}/login`;
          }

          subscriber.next(undefined);
          subscriber.complete();
        }
      });
    });
  }
}
