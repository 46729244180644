import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment-timezone';

import { BooksService } from '@booking/app/services/books.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.sass'],
})
export class BookComponent implements OnInit {
  @ViewChild('stepper') public stepperElement: MatStepper;
  public values: any = {};
  public success: boolean = false;

  constructor(private bookService: BooksService) {}

  ngOnInit(): void {}

  public next(values: any): void {
    this.values = { ...this.values, ...values };
    this.stepperElement.next();
  }

  public previous(): void {
    this.stepperElement.previous();
  }

  public submit(): void {
    const f = new FormData();
    if (this.values.file) {
      f.append('file', this.values.file);
    }
    f.append(
      'living_start',
      moment.tz(this.values.living_start, 'Europe/Moscow').format().toString()
    );
    f.append('living_end', moment.tz(this.values.living_end, 'Europe/Moscow').format().toString());
    f.append('city', this.values.city);
    f.append('name', this.values.name);
    f.append('room_type', this.values.room_type);
    f.append('price', this.values.price);
    f.append('phone', this.values.phone);
    f.append('comment', this.values.comment);
    f.append('email', this.values.email);
    f.append('is_sms', this.values.is_sms);
    f.append('is_phone', this.values.is_phone);
    f.append('is_email', this.values.is_email);
    f.append('is_messenger', this.values.is_messenger);

    this.bookService.sendBook(f).subscribe(() => {
      this.success = true;
    });
  }
}
