import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

import { concatDates, defineRoomType } from '@booking/app/scripts/booking';
import { BooksService } from '@booking/app/services/books.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.sass'],
})
export class DocumentComponent implements OnInit {
  public defineRoomType: { (t: string): string } = defineRoomType;
  public defineRange: { (start: string, end: string): string } = concatDates;
  public isDocs: boolean = true;
  public form: FormGroup;
  public success: boolean;
  public detail: BookDetail;
  public bookID: string | null;
  public boxes: any = [
    {
      label: 'Чек об оплате',
      key: 'is_recipient',
      checked: false,
    },
    {
      label: 'Справка о проживании',
      key: 'is_certificate',
      checked: false,
    },
    {
      label: 'Прайс гостиницы',
      key: 'is_price',
      checked: false,
    },
    {
      label: 'Лицензия',
      key: 'is_license',
      checked: false,
    },
  ];

  constructor(private bookService: BooksService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getID();
    this.getBook();
    this.setForm();
  }

  private getID(): void {
    this.bookID = this.route.snapshot.paramMap.get('uuid');
  }

  private setForm(): void {
    this.form = new FormGroup({
      is_recipient: new FormControl(false),
      is_certificate: new FormControl(false),
      is_price: new FormControl(false),
      is_license: new FormControl(false),
      comment: new FormControl(''),
    });
  }

  private getBook(): void {
    if (!this.bookID) return;
    this.bookService.getShortBook(this.bookID).subscribe((b: BookDetail) => {
      this.detail = b;
    });
  }

  public reqBooks(): void {
    this.isDocs = false;
  }

  public submit(): void {
    if (!this.bookID) return;

    this.bookService.sendFormForDocuemnts(this.bookID, this.form.value).subscribe(() => {
      this.success = true;
    });
  }

  public setChecked(e: MouseEvent, id: number) {
    e.preventDefault();
    e.stopPropagation();

    this.boxes[id].checked = !this.boxes[id].checked;

    this.form.controls[this.boxes[id].key].setValue(!this.form.controls[this.boxes[id].key].value);
  }
}

type BookDetail = {
  book: {
    id: string;
    name: string;
    phone: string;
    email: string;
  };
  hotel: {
    name: string;
    address: string;
    placement: string;
    living_start: string;
    living_end: string;
    price: string;
    room_type: string;
  };
};
