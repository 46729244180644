import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';

import { BooksService } from '@booking/app/services/books.service';

@Component({
  selector: 'app-document-stub',
  templateUrl: './status-stub.component.html',
  styleUrls: ['./status-stub.component.sass'],
  imports: [MatProgressSpinnerModule, MatFormFieldModule, MatIconModule],
  standalone: true,
})

export class StatusStubComponent implements OnInit {
  private session: string;
  public error: string = '';

  constructor(private route: ActivatedRoute, private bookService: BooksService, private router: Router) {}

  ngOnInit(): void {
    this.getSession();
  }

  private setError(error: string) {
    this.error = error;
  }

  private getSession() {
    this.route.queryParams.subscribe((v) => {
      this.session = v['orderId'];

      this.bookService.getPaymentStatus(this.session).subscribe(
        (resp) => {
          this.router.navigate(['documents', resp.uuid]).then(() => { location.reload() })
        },
        (err) => {
          this.setError(err.error.errorMessage);
        }
      );
    });
  }
}
