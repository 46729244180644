<div class="wrapper">
  <ng-container *ngIf="!success">
    <h1>Бронирование гостиницы</h1>

    <app-stepper-header [stepId]="stepper.selectedIndex"></app-stepper-header>

    <mat-card>
      <mat-stepper
        [linear]="true"
        #stepper
        selectedIndex="0"
      >
        <mat-step
          state="personal_data"
          color="accent"
        >
          <ng-template matStepContent
            ><app-personal (onNext)="next($event)"></app-personal
          ></ng-template>
        </mat-step>
        <mat-step
          state="check_list"
          color="accent"
        >
          <ng-template matStepContent
            ><app-tour
              (onNext)="next($event)"
              (onPrevious)="previous()"
            ></app-tour
          ></ng-template>
        </mat-step>
        <mat-step
          state="factory"
          color="accent"
        >
          <ng-template matStepContent
            ><app-checking
              [data]="values"
              (onPrevious)="previous()"
              (onSubmit)="submit()"
            ></app-checking
          ></ng-template>
        </mat-step>
      </mat-stepper>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="success">
    <mat-card class="status">
      <h2>Запрос на гостиницу взят в работу</h2>
      <p>Пришлем письмо для оплаты с подобранным отелем, либо перезвоним, если останутся вопросы</p>
      <img
        src="../../../assets/images/info.png"
        alt="success"
      />
    </mat-card>
  </ng-container>
</div>
