import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.sass'],
})
export class PersonalComponent implements OnInit {
  @ViewChild('inputPersonalFile') private inputPersonalFile: ElementRef<HTMLInputElement>;
  @Output() public onNext = new EventEmitter<{
    name: string;
    phone: string;
    email: string;
    comment: string;
    file: File;
  }>();

  public personalData: FormGroup;
  public filePersonalLoadError: boolean;
  public boxes: { label: string; checked: boolean; key: string }[] = [
    {
      label: 'Звонок',
      checked: false,
      key: 'is_phone',
    },
    {
      label: 'СМС',
      checked: false,
      key: 'is_sms',
    },
    {
      label: 'Почта',
      checked: false,
      key: 'is_email',
    },
    {
      label: 'Мессенджеры (Ватсапп/Телеграм)',
      checked: false,
      key: 'is_messenger',
    },
  ];
  public isPhoneExist: boolean;

  constructor() {}

  ngOnInit(): void {
    this.setForm();
  }

  public alert() {
    this.personalData.markAllAsTouched();
  }

  public setChecked(e: MouseEvent, id: number) {
    e.preventDefault();
    e.stopPropagation();

    this.boxes[id].checked = !this.boxes[id].checked;

    this.personalData.controls[this.boxes[id].key].setValue(
      !this.personalData.controls[this.boxes[id].key].value
    );
  }

  public getColorStyle(condition: boolean, err?: boolean): string {
    return `color: ${this.getColor(condition, err)};`;
  }

  public getBgStyle(condition: boolean): string {
    return `background-color: ${this.getColor(condition)};`;
  }

  private getColor(cond: boolean, err?: boolean): string {
    if (err) return '#f44336';

    return cond ? '#1a85e8' : '#B9BCD6';
  }

  public resetFileByInstance(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.personalData.controls['file'].reset();
    this.inputPersonalFile.nativeElement.value = '';
  }

  public attachPersonalFile(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.filePersonalLoadError = false;
    this.inputPersonalFile.nativeElement.click();
  }

  public pickIcon(field: string): string {
    if (!this.personalData.controls[field].touched) return 'valid_mark';
    if (this.personalData.controls[field].errors !== null) return 'error';

    return 'valid_mark';
  }

  public getStyleColor(field: string): string {
    return `color: ${this.pickColor(field)};`;
  }

  private pickColor(field: string): string {
    if (!this.personalData.controls[field].touched) return UNTOUCHED_COLOR;
    if (this.personalData.controls[field].errors !== null) return ERROR_COLOR;

    return VALID_COLOR;
  }

  private setForm(): void {
    this.personalData = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      comment: new FormControl('', []),
      file: new FormControl('', []),
      is_phone: new FormControl(false, []),
      is_sms: new FormControl(false, []),
      is_email: new FormControl(false, []),
      is_messenger: new FormControl(false, []),
    });

    this.personalData.valueChanges.subscribe((a: any) => {
      if (a.phone) this.isPhoneExist = true;
    });
  }

  public handlePersonalFile(e: Event): void {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (!e.target.files) return;
    if (!e.target.files[0]) return;

    if (e.target.files[0].size > 5 * 1024 * 1024) {
      this.filePersonalLoadError = true;
      return;
    }

    this.personalData.controls['file'].setValue(e.target.files[0]);
  }

  public submit(): void {
    this.personalData.markAllAsTouched();
    if (this.personalData.status === 'INVALID') return;

    this.onNext.emit(this.personalData.value);
  }
}

const ERROR_COLOR = 'red';
const VALID_COLOR = '#1A85E8';
const UNTOUCHED_COLOR = '#B9BCD6';
