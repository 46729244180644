import { Injectable } from '@angular/core';
import { Admin } from '@uslugi/app/services/admin.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public auth: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  public admin: BehaviorSubject<Admin | undefined> = new BehaviorSubject<Admin | undefined>(
    undefined
  );

  constructor() {}

  public set token(val: string) {
    localStorage.setItem('orionToken', val);
  }

  public get token(): string {
    return localStorage.getItem('orionToken') || '';
  }

  public clearSession(): void {
    this.token = '';
  }
}
