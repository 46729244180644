import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { concatDates, defineRoomType, getCommunicate } from '@booking/app/scripts/booking';

@Component({
  selector: 'app-checking',
  templateUrl: './checking.component.html',
  styleUrls: ['./checking.component.sass'],
})
export class CheckingComponent implements OnInit, OnChanges {
  @Input() public data: any;
  @Output() public onPrevious: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onSubmit: EventEmitter<void> = new EventEmitter<void>();
  public gteRoomType: { (kind: string): string } = defineRoomType;
  public getRange: { (start: string | undefined, end: string | undefined): string } = concatDates;
  public getCommunication: { (data: string): string } = getCommunicate;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  public submit(): void {
    this.onSubmit.emit()
  }

  public previous(): void {
    this.onPrevious.emit();
  }
}
