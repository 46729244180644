import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { round } from 'lodash';
import * as moment from 'moment';

import { BookDetail } from '@booking/app/schema/book';
import { pluralize } from '@booking/app/scripts/helper';
import { BooksService } from '@booking/app/services/books.service';

@Component({
  selector: 'app-book-info',
  templateUrl: './book-info.component.html',
  styleUrls: ['./book-info.component.sass'],
})
export class BookInfoComponent implements OnInit {
  public detatil: BookDetail | undefined;
  public isActive: boolean = true;
  public books:
    | {
        id: number;
        status: string;
        phone: string;
        city: string;
        name: string;
        book_id: number;
        created_at: Date;
      }[]
    | undefined;
  public form: FormGroup;
  public diff: number;

  constructor(
    private bookService: BooksService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getRequests();
    this.initBook();
    this.setForm();
  }

  private setForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      placement: new FormControl('', [Validators.required]),
      cost: new FormControl(null, [Validators.required]),
    });
  }

  public getRange(): string {
    if (!this.detatil) return '';

    const start = moment(this.detatil.living_start).format('DD.MM.YYYY');
    const end = moment(this.detatil.living_end).format('DD.MM.YYYY');

    return `${start} - ${end}`;
  }
  private getRequests(): void {
    this.bookService.getBooks('').subscribe((v) => {
      const currentRequest = v.filter((el: any) => el.payment.booking_id == this.detatil?.id)[0]
      this.isActive = currentRequest.request.status === 'active';
    });
  }

  public getLabel(): string {
    if (!this.detatil) return '';

    if (this.detatil.room_type === 'single') return 'одноместный';
    if (this.detatil.room_type === 'double') return 'двумхместный';
    if (this.detatil.room_type === 'triple') return 'тремхместный';

    return '';
  }
  public getCommunicate(): string {
    const ways = [];

    if (!this.detatil) return '';

    if (this.detatil.is_email) ways.push('по электронной почте');
    if (this.detatil.is_messenger) ways.push('через мессенджер');
    if (this.detatil.is_phone) ways.push('по телефону');
    if (this.detatil.is_sms) ways.push('СМС');

    return ways.join(', ').toString();
  }

  private initBook(): void {
    const id = this.getCurrentId();
    if (!id) return;

    this.getInfo(id);
  }

  private getCurrentId(): string | null {
    return this.route.snapshot.paramMap.get('id');
  }

  public getInfo(id: string): void {
    this.bookService.getBook(id).subscribe((v: BookDetail) => {
      this.detatil = v;

      if (this.detatil.hotel) {
        this.form.controls['name'].setValue(this.detatil.hotel.name);
        this.form.controls['address'].setValue(this.detatil.hotel.address);
        this.form.controls['placement'].setValue(this.detatil.hotel.placement);
        this.form.controls['cost'].setValue(this.detatil.hotel.cost);
      }

      this.diff = moment(v.living_end).diff(moment(v.living_start), 'days');
    });
  }

  public genLinkForPayture(): string {
    if (!this.detatil?.payment) return '';

    return this.detatil.payment.link;
  }

  public pickIcon(field: string): string {
    if (!this.form.controls[field].touched) return 'valid_mark';
    if (this.form.controls[field].errors !== null) return 'error';

    return 'valid_mark';
  }

  private pickColor(field: string): string {
    if (!this.form.controls[field].touched) return UNTOUCHED_COLOR;
    if (this.form.controls[field].errors !== null) return ERROR_COLOR;

    return VALID_COLOR;
  }

  public download(): void {
    if (!this.detatil) return;

    this.bookService.getStatic(this.detatil.attachment.uuid).subscribe((blob) => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const isSafariBrowser =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;

      if (isSafariBrowser) {
        link.setAttribute('target', '_blank');
      }
      link.setAttribute('href', url);
      if (!this.detatil) return;
      link.setAttribute('download', this.detatil.attachment.name);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  public getCost(): string {
    const price = this.form.controls['cost'].value;
    if (!price) return '';

    if (!this.diff) return `${price}₽/сут.`;

    const per = round(price / this.diff, 2);
    return `${per}₽/сут.`;
  }

  public getPluralizedLabel(): string {
    return `${this.diff} ${pluralize('день', 'дня', 'дней', this.diff)}`;
  }

  public getStyleColor(field: string): string {
    return `color: ${this.pickColor(field)};`;
  }

  public submit(): void {
    this.form.markAllAsTouched();

    if (this.form.status == 'INVALID') return;
    if (!this.detatil) return;

    this.bookService
      .sendToClient(this.detatil.id, this.form.value)
      .subscribe(() => this.router.navigate(['admin', 'books']));
  }
}

const ERROR_COLOR = '#EE9833';
const VALID_COLOR = '#1A85E8';
const UNTOUCHED_COLOR = '#B9BCD6';
