import { Component, OnInit } from '@angular/core';
import { environment } from '@booking/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  public currentTab: number;
  public uslugiURL: string = `${environment.uslugiURL}/admin/partners`;

  constructor() {}

  ngOnInit(): void {
    const i = Number(sessionStorage.getItem("tab"))
    this.currentTab = i

    this.subscribeTab();
  }

  private subscribeTab() {}

  public getActiveStyle(i: number): string {
    return this.currentTab === i ? 'color: #1A85E8; border-bottom: 2px solid #1A85E8' : '';
  }

  public route(i: number): void {
    this.currentTab = i;
    sessionStorage.setItem("tab", i.toString())
  }
}
