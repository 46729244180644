export const downloadBlob = (b: Blob, name: string) => {
  const url = URL.createObjectURL(b);
  const link = createLink(url, name);

  appendAndClick(link)
};

export const downloadCSV = (v: string, name: string) => {
  const universalBOM = '\uFEFF';
  const content = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + v);

  const link = createLink(content, `${name}.csv`);
  appendAndClick(link);
};

const createLink = (url: string, name: string): HTMLAnchorElement => {
  const link = document.createElement('a');
  const isSafariBrowser =
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

  if (isSafariBrowser) {
    link.setAttribute('target', '_blank');
  }
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  link.style.visibility = 'hidden';

  return link;
};

const appendAndClick = (el: HTMLAnchorElement) => {
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};
