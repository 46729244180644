import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';

import { BooksService } from '@booking/app/services/books.service';

@Component({
  selector: 'app-payment-stub',
  templateUrl: './payment-stub.component.html',
  styleUrls: ['./payment-stub.component.sass'],
  imports: [MatProgressSpinnerModule, MatFormFieldModule, MatIconModule],
  standalone: true,
})
export class PaymentStubComponent implements OnInit {
  private session: string;
  public error: string = '';

  constructor(
    private route: ActivatedRoute,
    private bookService: BooksService
  ) {}

  ngOnInit(): void {
    this.getSession();
  }

  private setError(error: string) {
    this.error = error;
  }

  private getSession() {
    this.route.queryParams.subscribe((v) => {
      this.session = v['session'];

      this.bookService.getPaymentLink(this.session).subscribe(
        (resp) => location.href = resp.link,
        (err) => {
          this.setError(err.error.errorMessage);
        }
      );
    });
  }
}
