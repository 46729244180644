<div class="bg-white">
  <div class="wrapper">
    <img
      src="../../../assets/images/logo.svg"
      alt="logo"
    />
    <div class="controls">
      <div class="inner-controls">
        <a
          href="admin/books"
          class="tab-el"
          (click)="route(0)"
          [style]="getActiveStyle(0)"
          >Проживание</a
        >
        <a
          href="admin/documents"
          class="tab-el"
          (click)="route(1)"
          [style]="getActiveStyle(1)"
          >Запрос на документы</a
        >
        <a
          [href]="uslugiURL"
          class="tab-el"
          >Наши объекты</a
        >
      </div>
    </div>
  </div>
</div>
