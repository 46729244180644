<div class="wrapper">
  <h1>{{ detatil?.name || 'Загрузка...' }}</h1>
</div>
<div class="bg-white">
  <div class="wrapper">
    <h2>Место проживания</h2>

    <div class="group">
      <form [formGroup]="form">
        <mat-form-field
          fill="always"
          appearance="outline"
          color="accent"
        >
          <mat-label>Название гостиницы </mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="name"
            [readonly]="!isActive"
          />
          <mat-error *ngIf="form.controls['name']?.hasError('required')"
            >Поле обязательно для заполнения</mat-error
          >
          <mat-icon
            *ngIf="isActive"
            [svgIcon]="pickIcon('name')"
            class="valid-mark"
            [style]="getStyleColor('name')"
          ></mat-icon>
        </mat-form-field>
        <mat-form-field
          fill="always"
          appearance="outline"
          color="accent"
        >
          <mat-label> Адрес </mat-label>
          <input
            matInput
            [readonly]="!isActive"
            autocomplete="off"
            formControlName="address"
          />
          <mat-error *ngIf="form.controls['address']?.hasError('required')"
            >Поле обязательно для заполнения</mat-error
          >
          <mat-icon
            *ngIf="isActive"
            [svgIcon]="pickIcon('address')"
            class="valid-mark"
            [style]="getStyleColor('address')"
          ></mat-icon>
        </mat-form-field>
        <mat-form-field
          fill="always"
          appearance="outline"
          color="accent"
        >
          <mat-label> Ссылка на карту </mat-label>
          <input
            matInput
            [readonly]="!isActive"
            autocomplete="off"
            formControlName="placement"
          />
          <mat-error *ngIf="form.controls['placement']?.hasError('required')"
            >Поле обязательно для заполнения</mat-error
          >
          <mat-icon
            *ngIf="isActive"
            [svgIcon]="pickIcon('placement')"
            class="valid-mark"
            [style]="getStyleColor('placement')"
          ></mat-icon>
        </mat-form-field>
        <div class="cost-calc">
          <mat-form-field
            fill="always"
            appearance="outline"
            color="accent"
            class="cost-field"
          >
            <mat-label> Стоимость проживания </mat-label>
            <input
              matInput
              autocomplete="off"
              [readonly]="!isActive"
              mask="separator"
              suffix=" ₽"
              [allowNegativeNumbers]="false"
              formControlName="cost"
            />
            <mat-error *ngIf="form.controls['cost']?.hasError('required')"
              >Поле обязательно для заполнения</mat-error
            >
            <mat-icon
              *ngIf="isActive"
              [svgIcon]="pickIcon('cost')"
              class="valid-mark"
              [style]="getStyleColor('cost')"
            ></mat-icon>
          </mat-form-field>
          <div class="cost-hint">
            <span class="hint">В запросе {{ getPluralizedLabel() }} проживания</span>
            <p>{{ getCost() }}</p>
          </div>
        </div>
        <mat-form-field
          fill="always"
          appearance="outline"
          color="accent"
        >
          <mat-label> Ссылка на оплату </mat-label>
          <input
            matInput
            readonly
            [readonly]="!isActive"
            autocomplete="off"
            [value]="genLinkForPayture()"
          />
        </mat-form-field>
      </form>
      <div class="cards">
        <div class="card-info">
          <p class="head">Город проживания</p>
          <p>{{ detatil?.city }}</p>
          <p class="head">Даты командировки</p>
          <p>{{ getRange() }}</p>
          <p class="head">Тип номера</p>
          <p>{{ getLabel() }}</p>
          <p class="head">Желаемая стоимость</p>
          <p>{{ detatil?.price }} ₽/сут.</p>
        </div>
        <div class="card-info">
          <p class="head">Телефон</p>
          <p>{{ detatil?.phone }}</p>
          <p class="head">Электронная почта</p>
          <p>{{ detatil?.email }}</p>
          <p class="head">Как связаться</p>
          <p>{{ getCommunicate() }}</p>
          <p class="head">Комментарий</p>
          <p>{{ detatil?.comment }}</p>
          <span
            (click)="download()"
            class="file"
            >{{ detatil?.attachment?.name }}</span
          >
        </div>
      </div>
    </div>

    <button
      mat-raised-button
      class="submit-button"
      [disabled]="!isActive"
      (click)="submit()"
      color="accent"
    >
      Отправить клиенту
    </button>
  </div>
</div>
