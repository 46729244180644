<mat-card>
  <div class="stepper-header" style="padding: 24px !important;">
    <ng-container *ngFor="let step of steps; let i = index">
      <ng-container *ngIf="i">
        <div
          class="divider"
          [style]="getBgStyle(stepId >= i)"
        ></div>
      </ng-container>
      <mat-icon
        [svgIcon]="step.icon"
        [style]="getColorStyle(stepId >= i)"
      ></mat-icon>
    </ng-container>
  </div>
</mat-card>
