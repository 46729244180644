<div class="tour-data">
  <h4>Информация о командировке</h4>
  <form [formGroup]="tourData">
    <mat-form-field
      appearance="outline"
      fill="always"
      color="accent"
    >
      <mat-label>Город проживания</mat-label>
      <input
        matInput
        autocomplete="off"
        required
        formControlName="city"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let city of cities"
          [value]="city"
          >{{ city }}</mat-option
        >
      </mat-autocomplete>
      <mat-error *ngIf="tourData.controls['city']?.hasError('required')"
        >Поле обязательно для заполнения</mat-error
      >
      <mat-icon
        [svgIcon]="pickIcon('city')"
        class="valid-mark"
        [style]="getStyleColor('city')"
      ></mat-icon>
    </mat-form-field>
    <div class="field">
      <div class="date-range">
        <div class="start">
          <mat-form-field
            fill="always"
            appearance="outline"
            color="accent"
          >
            <mat-label>Заезд</mat-label>
            <input
              matInput
              autocomplete="off"
              (focus)="handleInputClick(pickerStart)"
              [min]="today"
              [max]="
                (tourData.controls['living_end'].value && tourData.controls['living_end'].value) ||
                ''
              "
              formControlName="living_start"
              [matDatepicker]="pickerStart"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerStart"
            >
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>

            <mat-error *ngIf="tourData.controls['living_start']?.hasError('required')"
              >Поле обязательно для заполнения</mat-error
            >
          </mat-form-field>
        </div>
        <span *ngIf="!isSmallScreen"> — </span>
        <div class="end">
          <mat-form-field
            fill="always"
            appearance="outline"
            color="accent"
          >
            <mat-label>Выезд</mat-label>
            <input
              matInput
              autocomplete="off"
              formControlName="living_end"
              (focus)="handleInputClick(pickerEnd)"
              [matDatepicker]="pickerEnd"
              [min]="today"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerEnd"
            >
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
            <mat-error *ngIf="tourData.controls['living_end']?.hasError('required')"
              >Поле обязательно для заполнения</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <mat-icon
        *ngIf="!isSmallScreen"
        [svgIcon]="pickIconForComplexField()"
        class="valid-mark"
        [style]="getStyleColorForComplexField()"
      ></mat-icon>
    </div>

    <div class="room-type">
      <mat-button-toggle-group
        [value]="selectedRoomType"
        (change)="changeSelect($event)"
      >
        <mat-button-toggle value="single">Одноместный</mat-button-toggle>
        <mat-button-toggle value="double">Двухместный</mat-button-toggle>
        <mat-button-toggle value="triple">Трёхместный</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-icon
        *ngIf="!isSmallScreen"
        svgIcon="valid_mark"
        class="valid-mark"
        color="accent"
      ></mat-icon>
    </div>
    <div class="price">
      <mat-form-field
        appearance="outline"
        fill="always"
        color="accent"
      >
        <mat-label>Желаемая стоимость, ₽/сут.</mat-label>
        <input
          matInput
          mask="separator"
          [allowNegativeNumbers]="false"
          autocomplete="off"
          suffix=" ₽"
          formControlName="price"
        />
      </mat-form-field>
      <p class="hint">Оставьте поле пустым, если цена не важна</p>
      <mat-icon
        *ngIf="!isSmallScreen"
        [svgIcon]="pickIcon('price')"
        class="valid-mark"
        [style]="getStyleColor('price')"
      ></mat-icon>
    </div>
  </form>
  <div class="button-controls">
    <button
      mat-raised-button
      matStepperPrevious
      class="back-button"
    >
      Вернуться назад
    </button>
    <button
      mat-raised-button
      color="accent"
      (click)="onClick()"
    >
      Продолжить
    </button>
  </div>
</div>
