import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.sass'],
})
export class AdminLayoutComponent implements OnInit {
  constructor(private registry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.setIcons();
  }

  ngOnInit(): void {}

  private setIcons() {
    this.registryIcon('check_list', '../assets/icons/check_list.svg');
    this.registryIcon('factory', '../assets/icons/factory.svg');
    this.registryIcon('personal_data', '../assets/icons/personal_data.svg');
    this.registryIcon('valid_mark', '../assets/icons/valid_mark.svg');
    this.registryIcon('pin', '../assets/icons/pin.svg');
    this.registryIcon('error', '../assets/icons/error.svg');
  }

  private registryIcon(name: string, path: string) {
    this.registry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
