import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@booking/app/guards/admin.guard';

import { AdminLayoutComponent } from '@booking/app/layouts/admin-layout/admin-layout.component';
import { AdminModule } from '@booking/app/pages/admin/admin.module';
import { BooksComponent } from '@booking/app/pages/admin/books/books.component';
import { DocsComponent } from '@booking/app/pages/admin/docs/docs.component';
import { BookInfoComponent } from '@booking/app/pages/information/book-info/book-info.component';
import { DocumentInfoComponent } from '@booking/app/pages/information/document-info/document-info.component';

import { HeaderModule } from '../../components/header/header.module';
import { InformationModule } from '../../pages/information/information.module';

const routes: Routes = [
  {
    path: '',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'books',
        component: BooksComponent,
      },
      {
        path: 'book/:id',
        component: BookInfoComponent,
      },
      {
        path: 'documents',
        component: DocsComponent,
      },
      {
        path: 'document/:id',
        component: DocumentInfoComponent,
      },
      {
        path: '**',
        redirectTo: 'admin/books',
      },
    ],
  },
];

@NgModule({
  declarations: [AdminLayoutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AdminModule,
    HeaderModule,
    InformationModule
  ],
})
export class AdminLayoutModule {}
