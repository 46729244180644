<div class="personal-data">
  <h3>Заполните данные для связи</h3>
  <form [formGroup]="personalData">
    <mat-form-field
      appearance="outline"
      fill="always"
      color="accent"
    >
      <mat-label>Фамилия, Имя и Отчество </mat-label>
      <input
        formControlName="name"
        matInput
        autocomplete="off"
        required
      />
      <mat-icon
        [svgIcon]="pickIcon('name')"
        class="valid-mark"
        [style]="getStyleColor('name')"
      ></mat-icon>
      <mat-error *ngIf="personalData.controls['name']?.hasError('required')"
        >Поле обязательно для заполнения</mat-error
      >
    </mat-form-field>
    <div class="communication">
      <mat-form-field
        appearance="outline"
        fill="always"
        color="accent"
      >
        <mat-label>Телефон</mat-label>
        <input
          matInput
          formControlName="phone"
          autocomplete="off"
          mask="(000) 000-00-00"
          [prefix]="isPhoneExist ? '+7 ' : ''"
          max="12"
          [showMaskTyped]="false"
          required
        />
        <mat-error *ngIf="personalData.controls['phone']?.hasError('required')"
          >Поле обязательно для заполнения</mat-error
        >
        <mat-error *ngIf="personalData.controls['phone']?.hasError('mask')"
          >Недействительый номер телефона</mat-error
        >
        <mat-icon
          [svgIcon]="pickIcon('phone')"
          class="valid-mark"
          [style]="getStyleColor('phone')"
        ></mat-icon>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        fill="always"
        color="accent"
      >
        <mat-label>Электронная почта</mat-label>
        <input
          matInput
          autocomplete="off"
          formControlName="email"
          max="50"
          type="email"
        />
        <mat-error *ngIf="personalData.controls['email']?.hasError('required')"
          >Поле обязательно для заполнения</mat-error
        >
        <mat-error *ngIf="personalData.controls['email']?.hasError('email')"
          >Электронный ящик недействительный</mat-error
        >
        <mat-icon
        [svgIcon]="pickIcon('email')"
          class="valid-mark"
          [style]="getStyleColor('email')"
        ></mat-icon>
      </mat-form-field>
    </div>
    <h4 style="margin-top: 10px">Как с вами связаться</h4>
    <ul>
      <li *ngFor="let box of boxes; let i = index">
        <mat-checkbox
          [checked]="box.checked"
          [class]="box.checked ? 'active' : 'inactive'"
          (click)="setChecked($event, i)"
        >
          <h4>{{ box.label }}</h4>
        </mat-checkbox>
      </li>
    </ul>
    <mat-form-field
      appearance="outline"
      fill="always"
      color="accent"
    >
      <mat-label>Комментарий</mat-label>
      <input
        matInput
        formControlName="comment"
        autocomplete="off"
      />
      <mat-error *ngIf="personalData.controls['comment']?.hasError('required')"
        >Поле обязательно для заполнения</mat-error
      >
    </mat-form-field>
    <ng-container
      *ngIf="!personalData.controls['file'].value || !personalData.controls['file'].value?.size"
    >
      <button
        class="pin-file"
        (click)="attachPersonalFile($event)"
      >
        <mat-icon svgIcon="pin"></mat-icon>
        <span>Прикрепить файл</span>
      </button>
    </ng-container>
    <ng-container *ngIf="personalData.controls['file'].value">
      <button
        class="pin-file"
        (click)="resetFileByInstance($event)"
      >
        <mat-icon color="accent">close</mat-icon>
        <span>{{ personalData.controls['file'].value.name }}</span>
      </button>
    </ng-container>

    <mat-error *ngIf="filePersonalLoadError">Размер файла не должен превышать 5 Мб</mat-error>
  </form>
  <button
    (click)="submit()"
    mat-raised-button
    color="accent"
  >
    Продолжить
  </button>
</div>

<input
  #inputPersonalFile
  type="file"
  style="display: none"
  accept="application/zip, application/vnd.rar, application/pdf, image/jpeg, image/png"
  (change)="handlePersonalFile($event)"
/>
